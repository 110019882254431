import Vue from 'vue/dist/vue.esm.js';

Vue.component('event-payment-methods', {
    template: `#event-payment-methods`,
    delimiters: ['[[', ']]'],
    props: {

        hasUser: {
            required: true,
            type: Number,
        },
        userCards: {
            required: false,
            type: Array,
        }
    },

    mounted() {

        if (this.$refs.selectedPaymentMethod.dataset.val !== '') {
            this.selectPaymentMethod(this.$refs.selectedPaymentMethod.dataset.val);
        }

        if (parseInt(this.$refs.selectedCard.dataset.val)) {
            this.selectCard(this.userCards.filter(d => d.id === parseInt(this.$refs.selectedCard.dataset.val))[0]);
        }
    },


    data() {
        return {

            selectedPaymentMethod: null,
            selectedCard: null,

        }
    },

    methods: {

        validateRef(ref) {
            if (this.$refs[ref] !== undefined) {
                return true;
            }
            return false;
        },
        isIOS() {
            const ua = navigator.userAgent;
            // Check for macOS or iOS
            const isMacOrIOS = /Macintosh|Mac OS X|iPad|iPhone|iPod/.test(ua);
            // Check if the browser is Safari
            const isSafari = /Safari/.test(ua) && !/Chrome|CriOS|FxiOS|OPR|Edg/.test(ua);
            return isMacOrIOS && isSafari;

            // const ua = navigator.userAgent;
            // const isMacSafari = /Macintosh/.test(ua) && /Safari/.test(ua) && !/Chrome/.test(ua);
            // const isIOS = /iPad|iPhone|iPod/.test(ua) && !window.MSStream && /Safari/.test(ua);
            //
            // return isMacSafari || isIOS;
        },

        selectPaymentMethod(paymentMethod) {

            if (paymentMethod ===
                this.selectedPaymentMethod) {
                return;
            }
            this.selectedPaymentMethod = paymentMethod;
            this.selectedCard = null;

            if (paymentMethod === 'credit_card' && this.userCards.length > 1) {
                this.selectCard(this.userCards.filter(d => d.isPrimary === true)[0]);
            }
            EventManager.fire('load-event-checkout-payment-method', null);
        },
        selectCard(card) {
            if (card === this.selectedCard) {
                return;
            }
            this.selectedCard = card;
        },

    },

    computed: {}
});