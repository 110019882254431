import {Controller} from "stimulus"


export default class extends Controller {
    connect() {
        this.captureUrl = this.element.getAttribute('data-capture-url');
        this.checkoutId = this.element.getAttribute('data-checkout-id');
        this.customerId = this.element.getAttribute('data-customer-id');

        EventManager.listen('sumup_widget_paid_event', (payload) => {
            payload.checkoutId = this.checkoutId;
            payload.customerId = this.customerId;

            let captureUrl = this.captureUrl;

            this.postData(captureUrl, payload).then((response) => {
                if (response.success && response.redirectUrl) {
                    window.location.href = response.redirectUrl;
                }
            });
        });
    }

    async postData(url = '', data = {}) {
        const response = await fetch(url, {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json'
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body: JSON.stringify(data)
        });
        return response.json();
    };
}